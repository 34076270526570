import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapPageElement = ({ element }) => {

    let pathname = element?.props?.location?.pathname
    let switching = 0

    if(pathname === '/contact/' || pathname === '/server-contact/' || pathname === '/joinus-contact/'){
        switching = 1
    }

    return (
        <>
            {switching === 1 ? (
                <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITEKEY}>
                    {element}
                </GoogleReCaptchaProvider>
            ):(
                element
            )}
        </>
    )
}

// このサイト内での遷移なら、prevLocationで前のページのパスが取れる。
// export const onRouteUpdate = ({ location, prevLocation }) => {
//     console.log('new pathname', location.pathname)
//     console.log('old pathname', prevLocation ? prevLocation.pathname : null)    
// }